import {
  inject,
  bindable,
  customElement,
  bindingMode
} from 'aurelia-framework';
import './user-roles.scss';

@customElement('user-roles')
@inject(Element)
export class UserRoles {

  // @bindable({defaultBindingMode: bindingMode.twoWay}) roles;
  @bindable rolesList;
  @bindable roles;

  constructor(element) {
    this.element = element;

    this.roles = [];
    this.rolesList = [];

    this.onRoleClick = this.onRoleClick.bind(this);
  }

  rolesChanged(roles) {
    this.updateRoles();
  }

  rolesListChanged(roles) {
    this.updateRoles();
  }

  updateRoles() {
    if (this.rolesList && this.roles) {
      this.rolesList.forEach(r => {
        r.active = !!this.roles.filter(x => x.id === r.id).length
      });
    }
  }

  onRoleClick(event, role) {
    if (role) {
      role.active = !role.active;
      this.roles = this.rolesList.filter(x => x.active)

      this.element.dispatchEvent(new CustomEvent('on-role-click', {
        detail: {
          event,
          role
        },
        bubbles: true
      }));
    }
  }
}
