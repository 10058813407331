/**
 * Limit/slice list to x elements
 * @param {Array} list of items
 * @param {Number} count number of counts to limit list
 * @return {Array} sliced list limited by number
 */
export class LimitListValueConverter {
  toView(array, count) {
    return array.slice(0, count);
  }
}
