import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import uiService from 'services/uiService';

import './whats-new-dialog.scss';

@inject(DialogController)
export class WhatsNewDialog {
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    
    uiService.whatsNew = false;
  }

  onClose() {
    this.dialogController.close()
  }
}
