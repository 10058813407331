import Utils from 'utils/utils';

/**
 * Filter array of object by property value
 * @param {Array} list of items
 * @param {String} propertyName name of property to filter
 * @param {String} value to filter propertyName by
 * @param {Boolean} strictMode return empty if filter is empty
 * @return {Array} list of filtered items 
 * 
 * If the filter starts with `=` search is done by exact match/equality
 */
export class FilterListValueConverter {
  toView(array, propertyName, filter, strictMode) {
    return FilterListValueConverter.filter(array, propertyName, filter, strictMode);
  }

  static filter(array, propertyName, filter, strictMode) {
    if (strictMode && (!filter || !filter.length)) {
      return [];
    }
    return Utils.filterArrayObj(array, propertyName, filter);
  }
}
