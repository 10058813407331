/** @format */

import { valueConverter } from 'aurelia-framework';

import uiService from 'services/uiService';
import { ASSET_NAME_FORMATS as FORMATS } from 'services/api/assetsService';

// const withAsset = fn => a => fn(a);
const FORMAT_CONVERTER = {
  [FORMATS.driver]: a => getDriverName(a),
  [FORMATS.vehicle]: a => getVehiclePlate(a),
  [FORMATS.driverAndVehicle]: a => getDriverAndPlate(a),
  [FORMATS.vehicleAndDriver]: a => getDriverAndPlate(a, true)
};

@valueConverter('asset-formatted-name')
export class AssetNameFormatter {
  toView(name, asset) {
    return AssetNameFormatter.formatName(asset) || name;
  }

  static formatName(asset) {
    return AssetNameFormatter.format(asset, uiService.assetNameFormat);
  }

  static format(asset, format) {
    if (!asset) return null;
    if (!FORMAT_CONVERTER[format]) return null;

    return FORMAT_CONVERTER[format](asset);
  }
}

//Maybe makes sense moving this as getters into Asset class?
const getDriverName = asset => {
  if (!asset) return;
  return (asset.snapshot && asset.snapshot.driverName) || asset.driverName;
};

const getVehiclePlate = asset => {
  if (!asset) return;
  return (asset.vehicle && asset.vehicle.plateNumber) || null;
};

const getDriverAndPlate = (asset, reversed) => {
  const driver = getDriverName(asset);
  const plate = getVehiclePlate(asset);

  if (!driver) return plate;
  if (!plate) return driver;

  return !reversed ? `${driver}, ${plate}` : `${plate}, ${driver}`;
};
