export class SortValueConverter {
  toView(array, field, reverse) {
    return SortValueConverter.sort(array, field, reverse);
  }

  static sort(array, field, reverse) {
    if (array && array.length) {
      let getKey = (x) => {
        return field ? x[field] : x
      };
      let parse = (x) => {
        return !x || !isNaN(x) ? x : x.toUpperCase()
      };
      let factor = !reverse ? 1 : -1;

      return array
        .slice(0)
        .sort((a, b) => {
          let _a = parse(getKey(a));
          let _b = parse(getKey(b));
          let _return_a = 1;
          let _return_b = 0;

          // Uncomment in case there is a desire to show empty/nulls/undefined fields on top in DESC order
          // if(reverse) {
          //   _return_a = 0;
          //   _return_b = 1;
          // }

          if (_a === '' || _a === null || typeof _a === "undefined") return _return_a
          if (_b === '' || _b === null || typeof _b === "undefined") return _return_b
          return ((_a > _b) - (_b > _a)) * factor;
        });
    }
  }
}
